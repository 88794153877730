<template>
  <div id="TasnateaList">
    <div>
      <div class="banner">
        <img src="../../assets/product/banner_tasantea.jpg" alt="" srcset="" />
      </div>
      <!-- 他山集经典系列 -->
      <div id="series_e" class="bg_i" style="margin-top: 77px">
        <div class="series_title">经典系列</div>
        <div class="series_dec">
          <div class="dec_item">品鉴级名优好茶</div>
          <div class="dec_item">匠心制作，享誉盛名</div>
          <div class="dec_item">把功夫茶装进三角茶包</div>
        </div>
        <!-- benner -->
        <div class="swiper_box">
          <swiper :options="swiperOption_i">
            <swiper-slide>
              <div class="banner_box" @click="toDetail(2, 5)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/v2/dahongpao.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="title">
                  <div class="title_left">大红袍</div>
                  <div class="title_right">
                    <img
                      src="../../assets/product/tuxing.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="banner_box" @click="toDetail(2, 6)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/v2/jinjunmei.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="title">
                  <div class="title_left">金骏眉</div>
                  <div class="title_right">
                    <img
                      src="../../assets/product/tuxing.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="banner_box" @click="toDetail(2, 7)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/v2/baimudan.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="title">
                  <div class="title_left">白牡丹</div>
                  <div class="title_right">
                    <img
                      src="../../assets/product/tuxing.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="banner_box" @click="toDetail(2, 21)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/v2/longjingcha.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="title">
                  <div class="title_left">龙井茶</div>
                  <div class="title_right">
                    <img
                      src="../../assets/product/tuxing.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </swiper-slide>
            <!-- <swiper-slide>
              <div class="banner_box" @click="toDetail(2, 22)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/dongfangmeiren.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="title">
                  <div class="title_left">东方美人</div>
                  <div class="title_right">
                    <img
                      src="../../assets/product/tuxing.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="banner_box" @click="toDetail(2, 23)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/fudingbaicha.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="title">
                  <div class="title_left">福鼎白茶</div>
                  <div class="title_right">
                    <img
                      src="../../assets/product/tuxing.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </swiper-slide> -->
          </swiper>
          <div
            class="swiper-button-prev swiper-button-prev_i"
            slot="button-prev"
          ></div>
          <div
            class="swiper-button-next swiper-button-next_i"
            slot="button-next"
          ></div>
        </div>
        <!-- model -->
        <div class="model"></div>
      </div>
      <!-- 他山集高山系列 -->
      <div id="series_f" class="bg_e">
        <div class="series_title">高山系列</div>
        <div class="series_dec">
          <div class="dec_item">高海拔优质好茶</div>
          <div class="dec_item">高山孕育高含量氨基酸</div>
          <div class="dec_item">鲜爽清甜，香气高扬</div>
        </div>
        <!-- benner -->
        <div class="swiper_box">
          <swiper :options="swiperOption_e">
            <swiper-slide>
              <div class="banner_box" @click="toDetail(2, 1)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/v2/mingqinalvcha.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="title">
                  <div class="title_left">明前绿茶</div>
                  <div class="title_right">
                    <img
                      src="../../assets/product/tuxing.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="banner_box" @click="toDetail(2, 2)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/v2/chenxiangpuer.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="title">
                  <div class="title_left">陈香普洱</div>
                  <div class="title_right">
                    <img
                      src="../../assets/product/tuxing.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="banner_box" @click="toDetail(2, 3)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/v2/qingxinwulong.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="title">
                  <div class="title_left">青心乌龙</div>
                  <div class="title_right">
                    <img
                      src="../../assets/product/tuxing.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="banner_box" @click="toDetail(2, 4)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/v2/mixianghongcha.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="title">
                  <div class="title_left">蜜香红茶</div>
                  <div class="title_right">
                    <img
                      src="../../assets/product/tuxing.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div
            class="swiper-button-prev swiper-button-prev_e"
            slot="button-prev"
          ></div>
          <div
            class="swiper-button-next swiper-button-next_e"
            slot="button-next"
          ></div>
        </div>
        <!-- model -->
        <div class="model"></div>
      </div>

      <!-- 他山集果味系列 -->
      <div id="series_i" class="bg_f">
        <div class="series_title">果味系列</div>
        <div class="series_dec">
          <div class="dec_item">上好乌龙茶邂逅</div>
          <div class="dec_item">清甜水蜜桃</div>
          <div class="dec_item">奶香椰肉</div>
          <div class="dec_item">清新葡萄</div>
          <div class="dec_item">茶香沁果香</div>
          <div class="dec_item">每一口都是浪漫的味道</div>
        </div>
        <!-- benner -->
        <div class="swiper_box">
          <swiper :options="swiperOption_l">
            <swiper-slide>
              <div class="banner_box" @click="toDetail(2, 8)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/baitaowulong.jpg"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="title">
                  <div class="title_left">白桃乌龙</div>
                  <div class="title_right">
                    <img
                      src="../../assets/product/tuxing.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="banner_box" @click="toDetail(2, 27)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/v2/mitaowulong.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="title">
                  <div class="title_left">蜜桃乌龙</div>
                  <div class="title_right">
                    <img
                      src="../../assets/product/tuxing.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="banner_box" @click="toDetail(2, 9)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/yeziwulong.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="title">
                  <div class="title_left">椰子乌龙</div>
                  <div class="title_right">
                    <img
                      src="../../assets/product/tuxing.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="banner_box" @click="toDetail(2, 24)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/putaowulong.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="title">
                  <div class="title_left">葡萄乌龙</div>
                  <div class="title_right">
                    <img
                      src="../../assets/product/tuxing.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div
            class="swiper-button-prev swiper-button-prev_l"
            slot="button-prev"
          ></div>
          <div
            class="swiper-button-next swiper-button-next_l"
            slot="button-next"
          ></div>
        </div>
      </div>
      <!-- 他山集花果系列 -->
      <div id="series_m" class="bg_m">
        <div class="series_title">花果系列</div>
        <div class="series_dec">
          <div class="dec_item">经典中国花茶、果茶</div>
          <div class="dec_item">三秋桂子、红粉蔷薇</div>
          <div class="dec_item">养身养胃，陈皮柑香</div>
        </div>
        <!-- benner -->
        <div class="swiper_box">
          <swiper :options="swiperOption_m">
            <swiper-slide>
              <div class="banner_box" @click="toDetail(2, 25)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/guihuawulong.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="title">
                  <div class="title_left">桂花乌龙</div>
                  <div class="title_right">
                    <img
                      src="../../assets/product/tuxing.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="banner_box" @click="toDetail(2, 28)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/v2/meiguidianhong.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="title">
                  <div class="title_left">玫瑰滇红</div>
                  <div class="title_right">
                    <img
                      src="../../assets/product/tuxing.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="banner_box" @click="toDetail(2, 29)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/v2/chenpipuer.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="title">
                  <div class="title_left">陈皮普洱</div>
                  <div class="title_right">
                    <img
                      src="../../assets/product/tuxing.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div
            class="swiper-button-prev swiper-button-prev_m"
            slot="button-prev"
          ></div>
          <div
            class="swiper-button-next swiper-button-next_m"
            slot="button-next"
          ></div>
        </div>
      </div>
      <!-- 他山集汉方养园系列 -->
      <div id="series_j" class="bg_j">
        <div class="series_title" style="height: 194px">汉方养园系列</div>
        <div class="series_dec">
          <div class="dec_item">甄选经典好茶</div>
          <div class="dec_item">拼配汉方养生食材</div>
          <div class="dec_item">专业团队反复测评</div>
          <div class="dec_item">终得黄金配比</div>
        </div>
        <!-- benner -->
        <div class="swiper_box">
          <swiper :options="swiperOption_j">
            <swiper-slide>
              <div class="banner_box" @click="toDetail(2, 10)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/dongguaheye.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="title">
                  <div class="title_left">冬瓜荷叶</div>
                  <div class="title_right">
                    <img
                      src="../../assets/product/tuxing.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="banner_box" @click="toDetail(2, 11)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/guiyuanhongzao.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="title">
                  <div class="title_left">桂圆红枣</div>
                  <div class="title_right">
                    <img
                      src="../../assets/product/tuxing.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="banner_box" @click="toDetail(2, 12)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/hongdouhuimi.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="title">
                  <div class="title_left">红豆薏米</div>
                  <div class="title_right">
                    <img
                      src="../../assets/product/tuxing.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </swiper-slide>

            <swiper-slide>
              <div class="banner_box" @click="toDetail(2, 13)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/jinguixueli.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="title">
                  <div class="title_left">金桂雪梨</div>
                  <div class="title_right">
                    <img
                      src="../../assets/product/tuxing.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="banner_box" @click="toDetail(2, 14)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/juehuajuemingzi.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="title">
                  <div class="title_left">菊花决明子</div>
                  <div class="title_right">
                    <img
                      src="../../assets/product/tuxing.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div
            class="swiper-button-prev swiper-button-prev_j"
            slot="button-prev"
          ></div>
          <div
            class="swiper-button-next swiper-button-next_j"
            slot="button-next"
          ></div>
        </div>
        <!-- model -->
        <div class="model"></div>
      </div>
      <!-- 他山集组合系列 -->
      <div id="series_k" class="bg_k">
        <div class="series_title">组合系列</div>
        <div class="series_dec" style="height: 265px">
          <div class="dec_item">汇聚“他山”茗茶</div>
          <div class="dec_item">源于用户场景需求</div>
          <div class="dec_item">拼配中式茶包组合</div>
        </div>
        <!-- benner -->
        <div class="swiper_box">
          <swiper :options="swiperOption_k">
            <swiper-slide>
              <div class="banner_box" @click="toDetail(2, 26)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/taohuaweiyang.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="title">
                  <div class="title_left">桃花未央</div>
                  <div class="title_right">
                    <img
                      src="../../assets/product/tuxing.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="banner_box" @click="toDetail(2, 20)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/xiarilengcui.jpg"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="title">
                  <div class="title_left">半夏冷萃</div>
                  <div class="title_right">
                    <img
                      src="../../assets/product/tuxing.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </swiper-slide>

            <swiper-slide>
              <div class="banner_box" @click="toDetail(2, 16)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/21tiandaka.jpg"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="title">
                  <div class="title_left">21天打卡装</div>
                  <div class="title_right">
                    <img
                      src="../../assets/product/tuxing.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="banner_box" @click="toDetail(2, 17)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/hongchadamanguan.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="title">
                  <div class="title_left">红茶大满贯</div>
                  <div class="title_right">
                    <img
                      src="../../assets/product/tuxing.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </swiper-slide>

            <swiper-slide>
              <div class="banner_box" @click="toDetail(2, 18)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/qiyeliangfan.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="title">
                  <div class="title_left">企业量贩装</div>
                  <div class="title_right">
                    <img
                      src="../../assets/product/tuxing.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="banner_box" @click="toDetail(2, 19)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/xiariguoguo.jpg"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="title">
                  <div class="title_left">夏日果果茶</div>
                  <div class="title_right">
                    <img
                      src="../../assets/product/tuxing.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </swiper-slide>

            <swiper-slide>
              <div class="banner_box" @click="toDetail(2, 15)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/10kouwei.jpg"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="title">
                  <div class="title_left">10口味组合装</div>
                  <div class="title_right">
                    <img
                      src="../../assets/product/tuxing.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div
            class="swiper-button-prev swiper-button-prev_k"
            slot="button-prev"
          ></div>
          <div
            class="swiper-button-next swiper-button-next_k"
            slot="button-next"
          ></div>
        </div>
        <!-- model -->
        <div class="model"></div>
      </div>
    </div>
    <PCConncatUs />
  </div>
</template>

<script>
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
import PCConncatUs from "@/components/PCConncatUs.vue";
import "swiper/dist/css/swiper.css";
import $ from "jquery";
export default {
  name: "TasnateaList",
  components: {
    swiper,
    swiperSlide,
    PCConncatUs,
  },

  props: {},
  setup() {},
  data() {
    return {
      swiperOption_i: {
        slidesPerView: 3,
        navigation: {
          nextEl: ".swiper-button-next_i",
          prevEl: ".swiper-button-prev_i",
          hideOnClick: true,
        },
      },
      swiperOption_e: {
        slidesPerView: 3,
        navigation: {
          nextEl: ".swiper-button-next_e",
          prevEl: ".swiper-button-prev_e",
          hideOnClick: true,
        },
      },
      swiperOption_j: {
        slidesPerView: 3,
        navigation: {
          nextEl: ".swiper-button-next_j",
          prevEl: ".swiper-button-prev_j",
          hideOnClick: true,
        },
      },
      swiperOption_k: {
        slidesPerView: 3,
        navigation: {
          nextEl: ".swiper-button-next_k",
          prevEl: ".swiper-button-prev_k",
          hideOnClick: true,
        },
      },
      swiperOption_l: {
        slidesPerView: 3,
        navigation: {
          nextEl: ".swiper-button-next_l",
          prevEl: ".swiper-button-prev_l",
          hideOnClick: true,
        },
      },
      swiperOption_m: {
        slidesPerView: 3,
        navigation: {
          nextEl: ".swiper-button-next_m",
          prevEl: ".swiper-button-prev_m",
          hideOnClick: true,
        },
      },
    };
  },
  methods: {
    toDetail(type, p_id) {
      this.$router.push(`/product/tasantea/detail?pid=${p_id}`);
    },
  },
  watch: {
    $route(newValue) {
      let height, banner_height;
      if (this.$route.query.h) {
        banner_height = $(".banner").height();
        let space;
        if ($(window).width() <= 1368) {
          space = 530;
        } else {
          space = 574;
        }
        height = (Number(newValue.query.h) - 1) * space + 20 + banner_height;
      } else {
        height = 0;
      }
      $("body,html").animate({ scrollTop: height }, 500);
    },
  },
  created() {
    this.$nextTick(() => {
      let height, banner_height;
      if (this.$route.query.h) {
        banner_height = $(".banner").height();
        height = (Number(this.$route.query.h) - 1) * 574 + 20 + banner_height;
        $("body,html").animate({ scrollTop: height }, 500);
      }
    });
  },
  mounted() {},
};
</script>

<style scoped lang="less">
#TasnateaList {
  .banner {
    width: 1006px;
    /* width: 100%; */
    /* height: 200px; */
    img {
      width: 100%;
      /*  height: 100%; */
    }
  }
  #series_e,
  #series_f,
  #series_i,
  #series_j,
  #series_m,
  #series_k {
    width: 1006px;
    /* width: 100%; */
    height: 320px;
    position: relative;
    margin-bottom: 254px;
    background-repeat: no-repeat;
    background-size: cover;
    .series_title {
      width: 60px;
      height: 142px;
      background: #45B035;
      font-size: 20px;
      font-weight: 500;
      color: #ffffff;
      writing-mode: vertical-lr; //垂直方向，从左向右
      text-align: center;
      line-height: 60px;
      letter-spacing: 5px;
      position: absolute;
      top: -37px;
      left: 24px;
      z-index: 998;
    }
    .series_dec {
      /*  width: 268px; */
      height: 250px;
      position: absolute;
      top: 18px;
      left: 122px;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      z-index: 998;
      .dec_item {
        width: 24px;
        height: 100%;
        writing-mode: vertical-lr; //垂直方向，从左向右
        /* line-height: 32px; */
        /* text-align: center; */
        letter-spacing: 6px;
        float: left;
        margin-right: 12px;
      }
    }
    .swiper_box {
      width: 890px;
      /*  width: 88.45%; */
      height: 263px;
      position: absolute;
      left: 58px;
      top: 240px;
      z-index: 998;
      .banner_box {
        width: 282px;
        /* width: 90%; */
        height: 216px;
        background: #fff;
        text-align: center;
        cursor: pointer;
        margin: 0 auto;
        padding: 4px 4px 0 4px;
        box-sizing: border-box;
        .img_box {
          width: 274px;
          /* width: 97.16%; */
          height: 180px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            transition: all 0.5s linear;
          }
        }

        .title {
          width: 100%;
          height: 36px;
          .title_left {
            float: left;
            font-size: 16px;
            font-weight: 400;
            color: #121212;
            line-height: 36px;
          }
          .title_right {
            float: right;
            width: 36px;
            height: 36px;
            line-height: 24px;
            transition: all 0.5s linear;
            img {
              width: 100%;
              height: 6px;
            }
          }
        }
        &:hover {
          .img_box {
            img {
              transform: scale(1.05);
            }
          }
          .title {
            .title_right {
              transform: translateX(5px);
              opacity: 0;
            }
          }
        }
      }
      & /deep/ .swiper-button-prev {
        left: -48px;
        top: 58%;
        outline: none;
        width: 36px;
        height: 36px;
        background-image: url("../../assets/product/icon_prev.png");
        background-size: 36px 36px;
      }
      & /deep/ .swiper-button-next {
        right: -48px;
        top: 58%;
        outline: none;
        width: 36px;
        height: 36px;
        background-image: url("../../assets/product/icon_next.png");
        background-size: 36px 36px;
      }
    }
    .item_box {
      width: 890px;
      height: 263px;
      position: absolute;
      left: 58px;
      top: 240px;
      cursor: pointer;
      z-index: 998;
      .item {
        width: 282px;
        height: 216px;
        background: #fff;
        text-align: center;
        cursor: pointer;
        float: left;
        margin-right: 24px;
        padding: 4px 4px 0 4px;
        box-sizing: border-box;
        .img_box {
          width: 274px;
          /*  width: 97.16%; */
          height: 180px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            transition: all 0.5s linear;
          }
        }

        .title {
          width: 100%;
          height: 36px;
          .title_left {
            float: left;
            font-size: 16px;
            font-weight: 400;
            color: #121212;
            line-height: 36px;
          }
          .title_right {
            float: right;
            width: 36px;
            height: 36px;
            line-height: 12px;
            transition: all 0.5s linear;
            img {
              width: 100%;
              height: 6px;
            }
          }
        }
        &:hover {
          .img_box {
            img {
              transform: scale(1.05);
            }
          }
          .title {
            .title_right {
              transform: translateX(5px);
              opacity: 0;
            }
          }
        }
      }
    }
    .model {
      /* width: 1006px; */
      width: 100%;
      height: 320px;
      position: absolute;
      left: 0;
      top: 0;
      background: black;
      opacity: 0.4;
      z-index: 997;
    }
  }
  .bg_e {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/series_bg/background_gaoshan_pc.png");
  }
  .bg_f {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/series_bg/bg_series_pc_huowei.jpg");
  }
  .bg_i {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/series_bg/background_series_pc_jingdian.png");
  }
  .bg_j {
    background-image: url("https://lehuozuihuayang.oss-cn-beijing.aliyuncs.com/2021Y/04M/202104231619162808123743.jpg");
  }
  .bg_k {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/zuhe_series_banner.jpg");
  }
  .bg_m {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/series_bg/bg_series_pc_huaguo.jpg");
  }
}
</style>
